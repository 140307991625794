import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GroupList from './user_group_list';
import GroupDetail from './user_group_detail';

class Group extends Component {
  componentDidMount(){
  }
  UNSAFE_UNSAFE_componentWillReceiveProps(nextProps){
    //console.log(nextProps);
    if(nextProps.group!=='Administrator'&&nextProps.group!==''){
      this.context.router.history.push('/');
    }
  }
  render() {
    //console.log(this.context);
    var params = this.context.router.route.match.params;
    var view;
    if(params.action===undefined){
      view = <GroupList/>;
    }else{
      if(params.id===undefined){
        view = <GroupDetail/>;
      }else{
        view = <GroupDetail action={params.action}/>;
      }
    }
    return (
      <div className="page-wrapper">{view}</div>
    );
  }
}

Group.contextTypes = {
  router: PropTypes.object
};

export default Group;
