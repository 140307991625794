import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import {TextField,Typography,Button} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
//import ImageControl from './shares/react_image_control';
import language from '../layout/language';
import classNames from 'classnames';
import Utils from '../api/api';
import theme,{styles} from '../layout/theme';

class GroupDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      _id:'',
      name:'',
      name_error:false,
      error:false,
      failed:false,
      message:'',
      isUpdate: false
    }
  }
  componentDidMount(){
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;
    if(params.id!==undefined){
      this.setState({isUpdate:true},() => {
        Utils.getSingleData('usergroup',params.id, function(res){
          //console.log(res);
          if(res.status==='success'&&res.results!==undefined){
            var data={};
            data.name = res.results.name;
            data._id = res.results._id;
            $this.setState(data,function(){
              //console.log($this.state);
            });
          }
        });
      });
    }
  }
  handleValidate(e){
    var obj = {};
    obj[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id+'_error';
    if(e.target.value.trim()===''||e.target.value===null){
      obj[p] = true;
    }else{
      obj[p] = false;
    }
    obj['failed'] = false;
    this.setState(obj,()=>{
      if(!this.state.name_error){
        obj['error'] = false;
      }else{
        obj['error'] = true;
      }
      this.setState(obj,()=>{
        //console.log(this.state);
      });
    });
  }
  handleSave(e){
    var params = this.context.router.route.match.params;
    //console.log(params);
    if(params.id!==undefined&&!this.state.isUpdate){
      this.setState({isUpdate:true})
    }
    var $this = this;
    var obj = {};
    if(this.state.error){
      return;
    }else{
      if(this.state.name===''){
        if(this.state.name==='')obj['name_error']=true;
        obj['error'] = true;
        this.setState(obj);
        return;
      }else{
        var data = {name:this.state.name};
        if(this.state.isUpdate){
          data._id = this.state._id;
          Utils._update('group',data,function(res){
            //console.log(res);
            if(res.status=='success'){
              $this.context.router.history.push('/group/');
            }else{
              $this.setState({failed:true,message:res.message});
            }
          });
        }else{
          Utils._add('group',data,function(res){
            //console.log(res);
            if(res.status=='success'){
              $this.context.router.history.push('/group/');
            }else{
              $this.setState({failed:true,message:res.message});
            }
          });
        }
      }
    }
  }
  handleCancel(){
    this.context.router.history.push('/group/');
  }
  render(){
    const classes = this.props.classes;
    const user = this.state;
    return(
      <div className="page-detail">
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error && classes.hide)}>Vui lòng nhập tất cả các field (*)</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
          <TextField
            id="name"
            label="Tên group"
            className={classes.textField}
            value={this.state.name}
            onChange={(event) => this.handleValidate(event)}
            onBlur={(event) => this.handleValidate(event)}
            margin="normal"
            required={true}
            error={this.state.name_error}
          />
          <FormGroup row className={classes.formRow}>
            <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
            <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event)}>{language.VN.btnSave}</Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

GroupDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
GroupDetail.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(GroupDetail);
