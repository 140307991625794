import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {Typography,/* Button,IconButton */Fab, Toolbar} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';

import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';


export class GroupList extends Component {
  constructor(props){
    super(props);
    this.state = {
      // data:[],
      // failed:false,
      // message:''
    }
  }
  // componentDidMount(){
  //   var $this = this;
  //   Utils.groups(function(data){
  //     if(data.status=='success'){
  //       $this.setState({data:data.results},function(){
  //         console.log($this.state);
  //       });
  //     }
  //   });
  // }
  handleAdd(){
    this.context.router.history.push('/group/add');
  }
  // handleEdit(obj){
  //   //console.log(obj);
  //   this.context.router.history.push('/group/edit/'+obj._id);
  // }
  // handleDelete(obj){
  //   var $this = this;
  //   Utils.group_delete(obj,function(res){
  //     //console.log(res);
  //     if(res.status=='success'){
  //       Utils.groups(function(data){
  //         if(data.status=='success'){
  //           $this.setState({data:data.results});
  //         }
  //       });
  //     }else{
  //       $this.setState({failed:true,message:res.message});
  //     }
  //   });
  // }
  render(){
    const $this = this;
    const classes = this.props.classes;

    var source = {
      thead: [{ title: 'Name', value: 'name' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.name
        })
      }
    };

    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục group</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar + ' flex-right'}>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='usergroup' apiTable='usergroup' router={this.context.router} dataMap={source} showCheckBox={false} keyword='' isSearch={false} >
          </ReactTable>
          {/* <Table className="table-responsive">
            <TableHead>
              <TableRow>
                <TableCell>Group</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map(n => {
                return (
                  <TableRow key={n._id}>
                    <TableCell>{n.name}</TableCell>
                    <TableCell className={classes.right}>
                      <IconButton className={classes.button} aria-label="Cập nhật" onClick={()=>this.handleEdit(n)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton className={classes.button} aria-label="Xóa" onClick={()=>this.handleDelete(n)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table> */}
        </Paper>
      </div>
    )
  }
}

GroupList.propTypes = {
  classes: PropTypes.object.isRequired,
};
GroupList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(GroupList);
